import React from "react";
import { graphql } from "gatsby";

import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import PartnerHero from "../components/Hero/PartnerHero";
import Layout from "../components/Layout/Layout";
import Post from "../components/Post/Post";


const PartnerTemplate = ({
  data
}) => {
  const {
    markdownRemark: {
      frontmatter: {
        title,
        position,
        email,
        phone,
        featuredImage: {
          childImageSharp: {
            fluid
          }
        },
        altText,
        description,
        template
      },
      html
    }
  } = data;
  
  return (
    <Layout
      title={ title }
      description={ description }
    >
      <Header
        colorScheme="light"
      />

      <PartnerHero
        title={ title }
        position={ position }
        phone={ phone }
        email={ email }
        image={ fluid }
        altText={ altText }
      />

      <Post
        body={ html }
        template={ template }
      />

      <Footer />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        title
        position
        email
        phone
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 600, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        altText
        description
        template
      }
    }
  }
`;

export default PartnerTemplate;
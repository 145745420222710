import React from "react";
import Img from "gatsby-image";


const PartnerHero = ({
  title,
  position,
  phone,
  email,
  image,
  altText
}) => {
  const phoneNumber = phone.length > 12 ? (phone.slice(0, 2) + " " + phone.slice(2, 5) + " " + phone.slice(5, 8) + " " + phone.slice(8, 12) + " ext. " + phone.slice(13, 14)) : (phone.slice(0, 2) + " " + phone.slice(2, 5) + " " + phone.slice(5, 8) + " " + phone.slice(8, 12));

  return (
    <section className="w-full pt-28 lg:pt-32">
      <div className="hidden lg:block lg:pt-24">
        <div className="flex flex-row items-center w-full">
          <div className="relative lg:w-7/12">
            <div className="lg:h-128 bg-blue-dark lg:w-full"></div>
            <div className="lg:h-32 bg-white lg:w-full"></div>
            <div className="lg:absolute lg:top-16 lg:right-16 w-96">
              <Img
                fluid={ image }
                alt={ altText }
              />
            </div>
          </div>
          <div className="lg:w-5/12">
            <div className="lg:pl-8">
              <h2 className="text-3xl md:text-4xl lg:text-5xl tracking-wide">
                <span>{ title }</span>
              </h2>
              <p className="pt-4 font-thin text-lg">
                <span className="block">{ position }</span>
                <span className="block text-gray-500"><a href={ "tel:" + phone }>{ phoneNumber }</a></span>
                <span className="block text-gray-500"><a href={ "mailto:" + email }>{ email }</a></span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:hidden flex flex-col md:flex-row md:items-center container mx-auto px-6 pt-4">
        <div className="w-full md:w-80">
          <Img
            fluid={ image }
            alt={ altText }
          />
        </div>
        <div className="pt-8 md:pt-0 md:pl-8">
          <h2 className="text-3xl md:text-4xl lg:text-5xl tracking-wide">
            <span>{ title }</span>
          </h2>
          <p className="pt-4 font-thin text-lg leading-5">
            <span className="block">{ position }</span>
            <span className="block text-gray-500"><a href={ "tel:" + phone }>{ phone.slice(0, 2) + " " + phone.slice(2, 5) + " " + phone.slice(5, 8) + " " + phone.slice(8, 12) + " ext. " + phone.slice(13, 14) }</a></span>
            <span className="block text-gray-500"><a href={ "mailto:" + email }>{ email }</a></span>
          </p>
        </div>
      </div>
    </section>
  )
}

export default PartnerHero;